import React, { useEffect } from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import checkUtmParams from '../../utils/checkUtmParams'
import EmailVerificationContainer from '../../containers/emailVerification'
import useHotjarScript from '../../utils/hooks/useHotjarScript'
import { isMobileView } from '../../utils/isMobileView'
import { MobileAuthContainer } from '../signup'
import MobileTopBar from '../../components/mobileTopBar'
import { navigate } from 'gatsby-link'
import asyncLocalStorage from '../../utils/asyncLocalStorage'
import { SIGNIN_STEPS } from '../../context/auth'

const Verify = () => {
  useEffect(() => {
    checkUtmParams()
  }, [])

  useHotjarScript()

  if (isMobileView()) {
    return (
      <MobileAuthContainer>
        <SEO title="Verify your email" />
        <MobileTopBar
          onBack={event => {
            event.preventDefault()
            asyncLocalStorage.removeItem('session').then(() => {
              navigate(SIGNIN_STEPS.LOGIN)
            })
          }}
        >
          Login
        </MobileTopBar>
        <div className="auth-mobile-body">
          <EmailVerificationContainer isSignIn={true} />
        </div>
      </MobileAuthContainer>
    )
  }

  return (
    <Layout>
      <SEO title="Verify your email" />
      <EmailVerificationContainer isSignIn={true} />
    </Layout>
  )
}

export default Verify
